<template>
  <UiFooterPage
    :text-copyright="`© ${dataYear} Koelner Rawlplug IP`"
    title="Warto być na bieżąco"
    description="Dołącz do naszego Newslettera aby nie ominęły Cię:"
  >
    <template #newsletterFooter>
      <div class="flex flex-wrap items-center md:flex-nowrap">
        <T3Renderer
          v-if="props.infobox && Object.keys(props.infobox).length"
          :content="[props.infobox]"
          :frame="false"
        />
        <component
          :is="isEcommerceEnabled ? 'T3CeRawlplugNewsletter' : null"
          v-if="props.newsletter?.content"
          class="ml-auto mt-12 w-full md:w-1/2"
          :content="props.newsletter.content"
        />
      </div>
    </template>
    <template #bottomLeft>
      <UiFooterSocial
        v-if="props?.social?.length"
        :socials="props.social"
      />
    </template>
    <template #bottomRight>
      <UiFooterLinks
        v-if="props.footerNavigation?.length"
        class="order-1 md:order-2 md:w-1/2"
        :footer-links="props.footerNavigation"
      />
    </template>
  </UiFooterPage>
</template>

<script lang="ts" setup>
import type { T3InitialData } from '@t3headless/nuxt-typo3'
import type { UiFooterSocialInterface } from '@ui/components/UiFooterSocial/UiFooterSocial.vue'
import useConditionalRender from '../../composables/useConditionalRender/useConditionalRender'

interface AppFooter {
  infobox: Pick<T3InitialData, 'infobox'>['infobox']
  newsletter: Pick<T3InitialData, 'newsletter'>['newsletter']
  social: UiFooterSocialInterface[] | undefined
  footerNavigation: Pick<T3InitialData, 'footerNavigation'>['footerNavigation']
}

const props = defineProps<AppFooter>()

const dataYear = computed(() => new Date().getFullYear())

const { isEcommerceEnabled } = useConditionalRender()
</script>
